import React from "react"
import { Container } from 'react-bootstrap';
import { Link } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from '@components/footer/footer';
import BookVideo from '@components/book-video/book-video';

const NotFoundPage = () => (
<>
  <Container>
    <SEO title="404: Not found" />
    <div className="not-found-page">
    <h1>Ooops!</h1>
    <p>Aliquam consectetur auctor nisl varius ornare. Integer non diam nec lectus pulvinar tristique eu nec orc curabitur.</p>
	<Link to="/" className="primary-button btn btn-primary">Return Home</Link>
    </div>
  </Container>
  <BookVideo />
  <Footer />
</>
)

export default NotFoundPage
